.rc_TopWords {
    background-color: var(--box-bg-color);
    border-radius: var(--box-border-radius);
    box-shadow:  var(--box-box-shadow)
}
.rc_TopWords:hover {
    box-shadow:  var(--box-box-shadow-hover)
}

.rc_TopWords .rc_component_title {
    font-weight: var(--box-title-font-weight);
    color: var(--color-light-green);
    font-size: var(--box-title-font-size);
}

.rc_TopWords .rc_info_line p {
    color: var(--box-info-p-color);
    font-weight: var(--box-info-p-font-weight);
}

.rc_TopWords .rc_info_line span {
    color: var(--box-info-span-color);
}

.loading_container {
    background-color: var(--box-bg-color) !important;
    opacity: 0.5;
    animation: pulse 1.4s ease-in-out infinite;
}

.loading_container > * {
    opacity: 0 !important;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        background-color: var(--box-bg-color);
        opacity: 0.9;
    }
    100% {
        opacity: 0.3;
    }
}

  


.rc_TopWords table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    color: var(--box-info-p-color);
  }
  
.rc_TopWords thead {
    background-color: var(--color-dark-31);  
}
  
  
.rc_TopWords tbody tr {
    border-top: 1px solid var(--color-dark-31);
  }
  
.rc_TopWords  td, th {
    padding: 8px;
    font-weight: normal;
  }
  
.rc_TopWords  th {
    font-weight: bold;
  }

  .rc_TopWords .NIS_sign {
    font-weight: bold;
    font-size: 20px;
  }
  
  
  @media screen and (min-width: 1350px) {
    .rc_TopWords {
        width: 50.7% !important;
    }
  
}
  
  @media screen and (min-width: 1200px) {
    .rc_TopWords {
        width: 50.9% !important;
    }
  
}