
.Main {
    width: 100%;
    margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
    .Main {
        width: 100%;
    }
}