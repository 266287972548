.rc_WebInfo {
    background-color: var(--box-bg-color);
    border-radius: var(--box-border-radius);
    box-shadow:  var(--box-box-shadow)
}

.rc_WebInfo:hover {
    box-shadow:  var(--box-box-shadow-hover)
}
.rc_WebInfo .rc_component_title {
    font-weight: var(--box-title-font-weight);
    color: var(--color-light-green);
    font-size: var(--box-title-font-size);
}

.rc_WebInfo .rc_info_line p {
    color: var(--box-info-p-color);
    font-weight: var(--box-info-p-font-weight);
}

.rc_WebInfo .rc_info_line span {
    color: var(--box-info-span-color);
}
.rc_WebInfo .icon {
    width: 17px;
    height: 17px;
}
.rc_WebInfo .rc_info_line {
    padding: 6px 4px;
}
.rc_WebInfo .subscribers_line:hover {
    background-color: var(--color-dark-29);
    cursor: pointer;
}



.loading_container {
    background-color: var(--box-bg-color) !important;
    opacity: 0.5;
    animation: pulse 1.4s ease-in-out infinite;
}

.loading_container > * {
    opacity: 0 !important;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        background-color: var(--box-bg-color);
        opacity: 0.9;
    }
    100% {
        opacity: 0.3;
    }
}

  