.DownloadWordsCSV {

}


.export-button {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    color: #fff;
    background-color: var(--color-light-green);;
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .export-button:hover {
    background-color: var(--color-light-green);
  }
  
  .export-button .icon {
    margin-right: 8px;
    font-size: 20px;
    color: #fff;
  }
  