.Login {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 350px;
}

.Login .Text_Conatiner  {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
	font-size: 30px;
    color: var(--color-dark);
}

.Login .login_google {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 55px;
    position: relative;
    bottom: 45px;
}

.Login .login_google .click_here_icon {
    font-size: 50px;
}

.Login .login_image_and_google {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 105px;
}

.Login .login_image_and_google img {
    width: 300px;
}

.Login .login_image_and_google button {
    padding: 18px 40px;
    background-color: rgba(240, 248, 255, 0.137);
    outline: 1px solid rgb(180, 217, 250);
    font-size: 18px;
    border: none;
    border-radius: 5px;
    color: var(--color-dark);
    cursor: pointer;
    display: flex;
    gap: 15px;
    align-items: center;
}
.Login .login_image_and_google button img {
    width: 20px;
}

.Login .login_image_and_google button:hover {
    transition: 0.2s;
    background-color: var(--color-white);
}


@media only screen and (max-width: 680px) {
    .Login {
        margin-bottom: 500px !important;
    }
    .Login .Text_Conatiner  {
        font-size: 20px;
    }

    .Login .login_google {
        gap: 25px;
        bottom: 0;
    }
    .Login .login_image_and_google {   
        flex-direction: column; 
        gap: 0;
    }
}



@media only screen and (max-width: 1000px) {
    .Login {
        margin-bottom: 50px;
        margin-top: 0;
    }

}