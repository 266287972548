.TermsOfService {
    display: flex;
    flex-direction: column;  
    align-items: center;
    margin: 0 auto;  
    width: 90%;
    margin-bottom: 250px;  
    direction: ltr !important;

}   

.TermsOfService .terms_container {
    text-align: left;
}
.TermsOfService h1 {
    color: var(--color-dark);
}

.TermsOfService h2 {
    color: var(--color-dark);
}

.TermsOfService p {
    color: var(--color-dark);
}

@media only screen and (max-width: 682px) {
.TermsOfService {
    margin-bottom: 500px;
}
}