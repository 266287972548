.rc_CustomersChart {
    background-color: var(--box-bg-color);
    border-radius: var(--box-border-radius);
    box-shadow:  var(--box-box-shadow)
}

.rc_CustomersChart:hover {
    box-shadow:  var(--box-box-shadow-hover)
}
.rc_CustomersChart .rc_component_title {
    font-weight: var(--box-title-font-weight);
    color: var(--color-light-green);
    font-size: var(--box-title-font-size);
}
.rc_CustomersChart .chart_types {
    height: 18px;
    color: var(--box-info-p-color);
}
.rc_CustomersChart .chart_types p {
    position: relative;
    bottom: 2.5px;
}

.rc_CustomersChart svg {
    margin-left: 5px;
    padding: 0;
    padding-top: 2px;
    width:15px;
}
.rc_CustomersChart .data_text p {
    margin: 0;
    font-size: 13px;
    padding: 0;
}

.rc_CustomersChart .rc_info_line p {
    color: var(--box-info-p-color);
    font-weight: var(--box-info-p-font-weight);
}

.rc_CustomersChart .rc_info_line span {
    color: var(--box-info-span-color);
}

.loading_container {
    background-color: var(--box-bg-color) !important;
    opacity: 0.5;
    animation: pulse 1.4s ease-in-out infinite;
}

.loading_container > * {
    opacity: 0 !important;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        background-color: var(--box-bg-color);
        opacity: 0.9;
    }
    100% {
        opacity: 0.3;
    }
}

.rc_chart_wrapper {
    flex: 1;
  }

  
  
  @media screen and (min-width: 1350px) {
    .rc_CustomersChart {
        width: 50.7% !important;
    }
  
}
  
  @media screen and (min-width: 1200px) {
    .rc_CustomersChart {
        width: 50.9% !important;
    }
  
}