.Footer {
    width: 100%;
    display: flex;
    margin: 0 auto;
    direction: rtl;
}



/* body {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
} */
ul { list-style: none; }
a { text-decoration: unset; }
a:hover { text-decoration: underline; }
ul > li > a { color:#fff; }
ul > li { line-height: 1.5em; }

.footer .p_left_20 {
    padding-left: 20px;
}
.footer .p_right_20 {
    padding-right: 20px;
}
.footer li:hover {
    transition: 0.1s;
    transform: translatex(-6px);
}

.footer {
    display: block;
    width: 100%;
    position: relative;
    /* background: #619d66; */
    background: #252525;
    padding: 150px 25px 50px 25px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.footer-start {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    width: 90%;
    margin: auto;
    padding: 20px 100px;
    /* background: #58E5A5; */
    background: var(--color-light-green);

    border-radius: 14px;
}
.start-learning {
    width: 90%;
    display: flex;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: -150px;
}
.footer .inner {
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 20px;
    max-width: 1180px;
    padding-right: 35px;
    margin: auto;
    text-align: right;
}

.main-logo {
    position: relative;
    display: flex;
    align-items: center;
}
.main-logo .logo {
    display: inline-block;
    width: 200px;
    height: 40px;
    margin-right: 10px;
}
@media only screen and (max-width: 942px) {
    .main-logo .logo {
        text-align: center;
        width: 100px;
    }
}
@media only screen and (max-width: 500px) {
    .main-logo .logo {
        position: absolute;
        bottom: 20px;
        width: 150px;
        left: 30%;
    }

    .last_column {
        margin-bottom: 50px;
    }
}
    .main-logo .logo > img {
    display: block;
    width: 100%;
    min-width: 40px;
}
.logo-info {
    text-align: left;
    line-height: 20px;
}
.text {
    font-size: 17px;
    line-height: 17px;
    color: #fff;
    letter-spacing: .18em;
    font-weight: 600;
    text-align: left;
}
.copyright {
    color: #fff;
    font-size: 12px;
    line-height: 12px;
}
.footer .column {
    width: 100%;
    font-size: 14px;
    text-align: right;
    
}
.footer .text-left {
    text-align: left;
}
.footer .column .column-title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0.5em;
    color: #fff;
}
.footer .column .column-title:not(:first-child) {
    margin-top: 1em;
}
.illustration-left {
    left: 8%;
    position: absolute;
    top: -42px;
}
.illustration-right{
    right: 8%;
    direction: ltr;
    position: absolute;
    top: -28px;
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
a.button {
    display: inline-flex;
    position: relative;
    cursor: pointer;
    height: 60px;
    margin-right: 20px;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    background: #fff;
    /* color: #55B85E; */
    color: var(--color-light-green);
    border-radius: 36px;
    font-size: 16px;
    font-weight: 700;
    padding: 0 30px;
    text-decoration: unset;
    box-shadow: 0px 24px 74px rgb(60 0 189 / 20%);
}
.section-title {
    font-size: 44px;
    font-weight: 700;
    color: #fff;
    
}
.section-sub-heading {
    font-size: 22px;
    font-weight: 400;
    color: #fff;
}
.section-sub-heading span {
    opacity: 0.85;
    
}
.section-sub-heading strong {
    font-weight: 600;
}

.newsletter_modal_btn {
    width: 40%;
    padding: 10px 0;
    background-color: var(--color-light-green);
    border: none;
    /* outline: 1px solid #252525; */
    color: #252525;
    /* box-shadow: 0px 0px 6px -2px #252525; */
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
}
.newsletter_modal_btn:hover {
    transition: 0.2s;
    box-shadow: 0 0 8px -1px var(--color-light-green);
}

@media only screen and (max-width: 990px) and (min-width: 200px) {
    body {
        /* height: 200vh; */
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .footer {
        padding: 0%;
    }

    .footer .inner {
        display: grid;
        margin-left: 30px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .start-learning {
        position: unset;
    }
    .footer-start {
        width: 100%;
        display: block;
        padding: 30px 20px;
        margin: 30px 0;
    }
    .section-title {
        font-size: 30px;
    }
    .section-sub-heading {
        font-size: 18px;
    }
    footer.footer {
        padding-top: 0;
    }
    a.button {
        height: 50px;
        margin-top: 10px;
    }
    .column.is-logo {
        order: 4;
    }
}

@media only screen and (max-width: 375px) and (min-width: 200px) {
.illustration-left{
    left: 1px;
}
}


.Footer .title {
    flex-grow: 1;
    max-width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.Footer .title2 {
    width:20%;
  }
  
 .Footer .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  