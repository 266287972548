.Articles {
    margin-bottom: 350px;
    display: flex;
    flex-direction: column;
    width: 100%;
}


.Articles .Articles_top_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 30px;
    color: var(--color-dark);
}

.Articles .articles_container {
    display: flex;
    justify-content: center;
    gap: 40px;
    align-items: center;
    margin-top: 50px;

}

.responsive-div {
    display: flex;
    gap: 40px;
}

.Articles .notLogged_articles_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-dark);
}

.Articles .articles_container .last_articles_container {
    border: 1px solid var(--color-dark);
    border-radius: 15px;
    width: 450px;
    overflow-y: auto;
    height: 450px;

}

.Articles .articles_container .create_new_article {
    border-radius: 15px;
    display: flex;
    position: relative;
    width: 450px;
    height: 450px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    cursor: pointer;
}

.Articles .articles_container .create_new_article:hover>img {
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.384);
}

.Articles .articles_container .create_new_article button {
    position: absolute;
    bottom: 45%;

    padding: 10px 40px;
    border-radius: 5px;
    background-color: var(--color-dark);
    color: var(--color-white);
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    z-index: 1;

}

.Articles .articles_container .create_new_article p {
    font-size: 33px;
    position: absolute;
    top: 25px;
    font-weight: bold;
    color: var(--color-dark);
    z-index: 1;
}

.Articles .articles_container .create_new_article img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    opacity: 0.6;
    box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.384);
}

.Articles .articles_container .article_by_user_container .article_title_desc .article_title {
    font-weight: bold;
    font-size: 16px;
    color: var(--color-dark);
}

.Articles .articles_container .article_by_user_container .article_title_desc .article_desc {
    color: var(--color-light-dark);
    font-size: 14px;
}

.Articles .articles_container .article_by_user_container .article_title_desc {
    display: flex;
    align-items: center;
    gap: 10px;
}

.Articles .articles_container .article_by_user_container {
    display: flex;
    padding: 35px 20px;
    width: 90%;
    margin: 0px auto;
    border-bottom: 1px solid var(--color-light-green);
    min-height: 30px;
    max-height: 60px;
    cursor: pointer;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
    font-size: 35px;
}

.Articles .articles_container .article_by_user_container .article_icon_title_container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.Articles .articles_container .article_icon_full {
    display: none;
}

.Articles .articles_container .article_icon_stroke {
    display: block;
}

.Articles .articles_container .article_by_user_container:hover .article_icon_full {
    display: block ;
}

.Articles .articles_container .article_by_user_container:hover .article_icon_stroke {
    display: none;
}


.no_articles_p {
    font-size: 20px;
    text-align: center;
    color: var(--color-dark);
}

.last_articles_container::-webkit-scrollbar {
    width: 9px;
}

.last_articles_container::-webkit-scrollbar-track {
    background-color: var(--color-white);
    border-radius: 20px;
}

.last_articles_container::-webkit-scrollbar-thumb {
    background: #464646;
    border-radius: 20px;
}

.last_articles_container::-webkit-scrollbar-thumb:hover {
    background-color: #575757;
    cursor: pointer !important;
}



.Articles .delete_icons {
    font-size: 25px;
}

.save .article_by_user_container .delete_icon_stoke:hover>.Articles .article_by_user_container .delete_icon_full {
    display: block ;
}


.Articles .delete_icon_full,
.Articles .delete_icon_stroke {
    display: none;
}

.article_by_user_container:hover .delete_icon_stroke {
    display: block;
}

.article_by_user_container .delete_icons:hover>.delete_icon_stroke {
    display: none !important;
}

.article_by_user_container .delete_icons:hover>.delete_icon_full {
    display: block ;
}


.Articles .articles_container .last_articles_container,
.Articles .articles_container .create_new_article {
    max-width: 100%;
}

@media only screen and (max-width: 925px) {
    .Articles {
        margin-bottom: 250px;
    }
    .articles_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .responsive-div {
        display: flex;
        flex-direction: column;
        gap: 40px;
        max-width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 750px) {
    .Articles {
        margin-bottom: 450px;
    }
}
@media only screen and (max-width: 500px) {
    .Articles {
        margin-bottom: 850px;
    }
    .Articles .articles_container .last_articles_container,
    .Articles .articles_container .create_new_article {
        width: 100%;
    }
    .responsive-div{
        margin-bottom: -250px;
    }

    .create_new_article p {
        text-align: center;
        word-wrap: break-word;
        padding: 0 15px;
    }
    .last_articles_container .article_by_user_container .article_title{
        font-size: 12px !important;
    }
    .delete_icon_stroke{
        display: block !important;
    }
    h1 {
        font-size:  35px;
    }
}