.DownloadExtension {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 780px;
    text-align: center;
    margin: 150px auto;
    z-index: 1;

}

.DownloadExtension h1 {
    font-size: 42px;
    color: var(--color-dark);
}

.DownloadExtension p {
    color: var(--color-dark);
}

.DownloadExtension .Download_Video_Container {
    display: flex;
    gap: 40px;
}

.DownloadExtension .Download_Video_Container button {
    width: 170px;
    height: 45px;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    margin-top: 40px;
    box-shadow: 0 0 6px -1px rgb(185, 185, 185);
    transition: 0.2s;
    transition: all .2s ease-in-out;
}


.DownloadExtension .Download_Video_Container button:hover {
    font-size: 18px;
}

.DownloadExtension .Download_Video_Container .btn_extension {
    background-color: var(--color-light-green);
    outline: 2px solid var(--color-light-green);
    color: var(--color-white);
    box-shadow: 0 0 5px 2px var(--color-light-green);
    cursor: pointer !important;

}

.DownloadExtension .Download_Video_Container .btn_video {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    outline: 1px solid rgb(203, 202, 202);
    background-color: var(--color-white);
    color: var(--color-light-dark);
}


@media only screen and (max-width: 680px) {
    .DownloadExtension {
        width: 80%;    
        margin: 50px auto;
    }

    .DownloadExtension h1 {
        font-size: 32px;
    }
    .DownloadExtension p {
        font-size: 14px;
    }

    .DownloadExtension .Download_Video_Container button {
        width: 120px;
        height: 35px;
        font-size: 12px;
    }
    .DownloadExtension .Download_Video_Container button:hover {
       font-size: 14px;
    }
}