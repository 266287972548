.Blog {
    text-align: center;
    margin-bottom: 400px;
}
.Blog .blog_main_img_container {
    margin-top: 50px;
}
.Blog .blog_main_img_container .main_image {
    width: 80%;
    border-radius: 15px;
    background-color: var(--color-light-dark);
    box-shadow: -1px 2px 13px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: -1px 2px 13px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -1px 2px 13px 1px rgba(0, 0, 0, 0.1);
}
.Blog .blog_articles_container {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: auto;
    position: relative;
    bottom: 90px;
    margin-bottom: -50px;
}
.Blog .blog_articles_container .article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 auto;
}
.Blog .blog_articles_container .article .article_title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: var(--color-dark);
    max-width: 70%;
    display: flex;
    margin: 10px auto 0px auto;
    direction: rtl;
}
.Blog .blog_articles_container .article .article_author {
    max-width: 70%;
    color: #8f8f8f;
    direction: rtl;
}
.Blog .blog_articles_container .article .image_article {
    cursor: pointer;
    width: 75%;
    border-radius: 15px;
    box-shadow: -1px 2px 13px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: -1px 2px 13px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -1px 2px 13px 1px rgba(0, 0, 0, 0.2);
}
.Blog .blog_articles_container .article .image_article:hover {
    transition: 0.2s;
    box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.3);
}


   
@media only screen and (max-width: 1000px) {
    .Blog {
        margin-bottom: 200px;

    }
    .Blog .blog_articles_container .article .article_author {
        display: none;
    }
    
    .Blog .blog_articles_container .article .image_article {
        width: 85%;
    }   
  }
@media only screen and (max-width: 750px) {
    .Blog .blog_articles_container  {
        flex-direction: column;
    }   
    .Blog .blog_articles_container .article .image_article {
        width: 60%;
    }   

    .Blog .blog_main_img_container .main_image {
        display: none;
    }

    .Blog .blog_articles_container {
        margin-bottom: 0;
        gap: 45px;
        bottom: 0;
    }
  }

  @media only screen and (max-width: 550px) {
    .Blog {
        margin-bottom: 100px;

    }
    .Blog .blog_articles_container .article .image_article {
        width: 80%;
    }   
  }



  @media only screen and (max-width: 680px) {
    .Blog {
        margin-bottom: 500px;
    }

}