.rc_ManageBlog {
    box-sizing: border-box;
    background-color: var(--box-bg-color);
    border-radius: var(--box-border-radius);
    box-shadow:  var(--box-box-shadow);
}
.rc_ManageBlog:hover {
    box-shadow:  var(--box-box-shadow-hover)
}
.rc_ManageBlog .rc_component_title {
    font-weight: var(--box-title-font-weight);
    color: var(--color-light-green);
    font-size: var(--box-title-font-size);
}

.rc_ManageBlog p {
    color: var(--box-info-p-color);
    font-weight: var(--box-info-p-font-weight);
}
.rc_ManageBlog span {
    color: var(--box-info-p-color);
}
/* 
.rc_ManageBlog #select_period {
    border: none;
    border-radius: 30px;
    padding: 4px 10px;
    outline: 1px solid #E1E1E1;
    cursor: pointer;
    
} */


.rc_ManageBlog .status_order_content {
    height: 75%;
}

.rc_ManageBlog .rc_data_text {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 20px;   
}


.rc_ManageBlog .rc_data_text_title {
    display: flex;
    width: 100%;
    height: 20px;
}

.rc_ManageBlog svg {
    margin-left: 5px;
    padding: 0;
    width:15px;
}

.rc_ManageBlog .rc_data_text p {
    font-size: 15px;
    font-weight: var(--box-info-p-font-weight);

}


/* .rc_ManageBlog .rc_data_text span {
    font-size: 15px;
    color: #777777;
}
 */




.loading_container {
    background-color: var(--box-bg-color) !important;
    opacity: 0.5;
    animation: pulse 1.4s ease-in-out infinite;
}

.loading_container > * {
    opacity: 0 !important;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        background-color: var(--box-bg-color);
        opacity: 0.9;
    }
    100% {
        opacity: 0.3;
    }
}



.manage_blog_line:hover {
    background-color: var(--color-dark-29);
}

.manage_blog_line {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 8px;
    
}


.manage_blog_line button {
    border: none;
    font-size: 12px;
    padding: 4px 20px;
    border-radius: 12px;
    background-color: var(--color-dark-31);
    color: var(--box-info-p-color)
}

.manage_blog_line button:hover {
    transition: 0.1s;
    outline: 1px solid var(--color-light-green) ;
}

.rc_ManageBlog .icon {
    width: 18px;
    height: 18px;
    /* bottom: 3px; */
    /* position: relative; */
}


.rc_ManageBlog  .title_id_line {
    width: 80%;
  }
  
.rc_ManageBlog  .title {
    flex-grow: 1;
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
.rc_ManageBlog  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  