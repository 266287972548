.rc_ManagerDashboard {
    max-width: 1690px;
    width: 90% !important;
    /* direction: rtl; */
    margin-bottom: 200px;
}   

:root {
    /* --main-bg-color: #F3F3FD; */
    --main-bg-color: #d5f4dc52;
    /* --box-bg-color: white; */
    /* --box-box-shadow: 3px 1px 10px 0px #00000013; */
    /* --box-box-shadow: 3px 1px 10px 0px #00000025; */
    --box-border-radius: 5px;
    /* --color-light-green: #d370ad; */
    /* --color-light-green: #21bc76; */
    --box-title-font-weight: 700;
    --box-title-font-size: 19px;
    --box-info-p-color: #292929;
    --box-info-p-font-weight: 500;
    --box-info-span-color: rgb(175, 175, 175);
  }
  

  p {
    margin-bottom: 0 !important;
  }

  @media only screen and (min-width: 730px)  and (max-width: 992px) {

    .webinfo_products_tablet_screen {
      max-width: 87.4vw;
    }
  }
  @media only screen and (min-width: 576px)  and (max-width: 730px) {

    .webinfo_products_tablet_screen {
      max-width: 86.5vw;
    }
  }
