.BlogArticle1 {
	margin-bottom: 350px;
    direction: rtl;

}

.blog_container {
    width: 70vw;
    margin: 30px auto;
    color: var(--color-dark);
}

.blog_container .breadcrumb {
    margin-bottom: 30px;
}
.blog_container h3 {
    font-weight: 500;
    font-size: 18px;
}
.blog_container h1 {
    font-weight: 700;
    font-size: 26px;
}

.blog_container .img_top_article img{
    width: 50%;
    margin: 30px 0;
}
.blog_container p {
color: var(--color-light-dark);
}
.blog_container .article_p {
    font-size: 19px;
    width: 70vw;
    white-space: pre-line;

}


.blog_container a {
    color: var(--color-dark);
}
.blog_container .article_p {
    color: var(--color-light-dark);
}


@media only screen and (max-width: 850px) {
    .BlogArticle1 {
        margin-bottom: 150px;
    }
    .blog_container {
        width: 90vw;
    }
.blog_container h1 {
    font-size: 20px;
}
.blog_container h3 {
    font-size: 17px;
    font-weight: 400;
}
.blog_container .article_p {
    font-size: 16px;
    width: 80vw;
}

.blog_container .img_top_article img{
    width: 70%;
}

}

@media only screen and (max-width: 550px) {
    .BlogArticle1 {
        margin-bottom: 50px;
    }
    .blog_container {
        width: 90vw;
    }
.blog_container h1 {
    font-size: 18px;
}
.blog_container h3 {
    font-size: 15px;
    font-weight: 400;
}
.blog_container .article_p {
    font-size: 14px;
    width: 80vw;
}

.blog_container .img_top_article img{
    width: 70%;
}
}
@media only screen and (max-width: 680px) {
    .BlogArticle1 {
        margin-bottom: 500px;
    }

}