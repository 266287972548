.rc_SiteData {
    box-sizing: border-box;
    background-color: var(--box-bg-color);
    border-radius: var(--box-border-radius);
    box-shadow:  var(--box-box-shadow)
}
.rc_SiteData:hover {
    box-shadow:  var(--box-box-shadow-hover)
}

.rc_SiteData .rc_component_title {
    font-weight: var(--box-title-font-weight);
    color: var(--color-light-green);
    font-size: var(--box-title-font-size);
}
.rc_SiteData p {
    color: var(--box-info-p-color);
}

.rc_SiteData #select_period {
    border: none;
    border-radius: 30px;
    padding: 4px 10px;
    outline: 1px solid #E1E1E1;
}





.rc_SiteData .data_container {
    display: flex;
    width: 100%;
    justify-content: start;
}

.rc_SiteData .data_text_container {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.rc_SiteData .data_text {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 15px;   
    /* margin-bottom: 15px; */

}

.rc_SiteData .data_text_title {
    display: flex;
    width: 100%;
    height: 20px;
    font-weight: bold;
}

.rc_SiteData svg {
    margin-left: 5px;
    padding: 0;
    width:15px;
}
.rc_SiteData .data_text p {
    margin: 0;
    font-size: 13px;
    padding: 0;
    padding-left: 10px !important;
}

.rc_SiteData .data_text .data_big_number {
    font-size: 30px;
    font-weight: 200;
    padding-left: 30px !important;
    height: 37px;
    line-height: 40px;
    font-weight: 500;
}

.rc_SiteData .line_two_container {
    display: flex;
    align-items: end;
    gap: 10px;
}

.rc_SiteData .line_three_container {
    padding-right: 20px;
    color: #777777;
    font-size: 15px;
}

.rc_SiteData .data_chart {
    display: flex;
    align-items: end;
}

.rc_SiteData .spliter {
    margin-top: 15px;
    border-top: 1px solid #E1E1E1;
    width: 97%;
}

.rc_SiteData .data_footer_container {
    display: flex;
    justify-content: space-around;
    width: 97%;
    
}
.rc_SiteData .data_footer_container p {
    font-size: 11px;
}
.rc_SiteData .data_footer_container span {
    font-size: 11px; 
    color: var(--box-info-p-color);
}





.loading_container {
    background-color: var(--box-bg-color) !important;
    opacity: 0.5;
    animation: pulse 1.4s ease-in-out infinite;
}

.loading_container > * {
    opacity: 0 !important;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        background-color: var(--box-bg-color);
        opacity: 0.9;
    }
    100% {
        opacity: 0.3;
    }
}


.rc_SiteData .loader {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: relative;
    top: 10px;
    animation: rotate 3s linear infinite
  }
  .rc_SiteData .loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid var(--color-dark);
    animation: prixClipFix 2s linear infinite ;
  }

  @keyframes rotate {
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }