.SavedWords {
    max-height: 300px;
    overflow-y: auto;
  }

  .SavedWords::-webkit-scrollbar {
    width: 9px;
  }
  
  .SavedWords::-webkit-scrollbar-track {
    background-color: var(--color-white);
    border-radius: 20px;
}

.SavedWords::-webkit-scrollbar-thumb {
    background: #464646;
    border-radius: 20px;
}

.SavedWords::-webkit-scrollbar-thumb:hover {
    background-color: #575757;
    cursor: pointer !important;
  }
  
.SavedWords .arrow_right {
    position: relative;
    top: 2.5px;
}

.SavedWords .save_words_lists_line {
    width: 90%;
    margin: 0 auto;
    margin:  var(--save-words-lists-line-margin-bottom) 5px !important;
    padding-bottom: var(--save-words-lists-line-margin-bottom) ;
}
.SavedWords .save_words_lists_line .icons {
    display: flex;
    gap: 10px;
}
    
  .save_words_lists_line .delete_icon_stoke:hover > .SavedWords .save_words_lists_line .delete_icon_full {
    display: block;
  }


 .SavedWords .delete_icon_full,
.SavedWords .delete_icon_stroke {
display: none;
}

.save_words_lists_line:hover .delete_icon_stroke {
    display: block;
}
.save_words_lists_line .delete_icons:hover > .delete_icon_stroke {
    display: none;
}
.save_words_lists_line .delete_icons:hover > .delete_icon_full {
    display: block;
}
.not_enough_words .saved_words_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.SavedWords .not_enough_words {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--color-dark);
}

.SavedWords .not_enough_words_p {
    font-weight: bold;
}

.SavedWords .not_enough_words_p2 {
    font-size: 15px;
}

.SavedWords .enough_words_p2{
    font-size: 15px;
    margin-top: 35px;
    margin-bottom: -15px;
    font-weight: 500;
    color: var(--color-light-green);
}


.SavedWords  .btn_extension {
    width: 150px;
    height: 30px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: 0 0 6px -1px rgb(185, 185, 185);
    transition: 0.2s;
    transition: all .2s ease-in-out;
    background-color: var(--color-light-green);
    outline: 2px solid var(--color-light-green);
    color: var(--color-white);
    box-shadow: 0 0 0px 4px var(--color-light-green);
    cursor: pointer !important;
}


.SavedWords .btn_extension:hover {
    font-size: 14.5px;
}