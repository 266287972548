.Header .header_logo {
	width: 180px;
}
.Header {
    width: 100%;
    margin: 0 auto;
    z-index: 10;

    
    position: sticky;
    top: 0;
}

.Header .MuiPaper-root {
    background-color: var(--color-white) !important;
    color: var(--color-dark);
}

.Header .MuiButtonBase-root{
    color: var(--color-dark) !important;
    font-size: 20px;
}

.Header .active {
    font-weight: bold;
}

.Header a {
    color:  var(--color-dark);
}

.Header a:hover {
    text-decoration: none;
}

@media only screen and (max-width: 900px) {
    .Header {
        direction: rtl;
    }

    .Header .MuiButtonBase-root{
        font-size: 27px !important;
        -ms-flex-pack: start;
    }

}


option {
    background-image:url("https://i.ibb.co/sjhwwxj/israel-1.png")

}
#hewbrew_language_option {
    width: 20px;
    height: 20px;
    background-image:url("https://i.ibb.co/sjhwwxj/israel-1.png")
}

#select-language {
    display: flex;
    width: 130px;
    justify-content: center;
    
}

.MuiMenu-paper .MuiMenuItem-root:hover {
    background-color: rgb(246, 246, 246) !important;
}