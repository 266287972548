.rc_ColorsSection
 {
    background-color: var(--box-bg-color);
    border-radius: var(--box-border-radius);
    box-shadow:  var(--box-box-shadow);
}

.rc_ColorsSection:hover {
    box-shadow:  var(--box-box-shadow-hover)
}

.rc_ColorsSection
 .rc_component_title {
    font-weight: var(--box-title-font-weight);
    color: var(--color-light-green);
    font-size: var(--box-title-font-size);
}

.rc_ColorsSection
 .rc_info_line {
    height: 30px;
    
}

.rc_ColorsSection
 .rc_info_line p {
    color: var(--box-info-p-color);
    font-weight: var(--box-info-p-font-weight);
}


.rc_ColorsSection
 .rc_info_line #main_color {
    -webkit-appearance: none;
    padding: 0;
    border: none;
    border-radius: 10px;
    width: 100px;
}

.rc_ColorsSection
 .rc_info_line #main_color::-webkit-color-swatch {
    border: none;
    border-radius: 10px;
    padding: 0;
}

.rc_ColorsSection
.rc_info_line #main_color::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 10px;
    padding: 0;
  }

.rc_ColorsSection
 .icon {
    width: 15px;
    height: 15px;
}



.loading_container {
    background-color: var(--box-bg-color) !important;
    opacity: 0.5;
    animation: pulse 1.4s ease-in-out infinite;
}

.loading_container > * {
    opacity: 0 !important;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        background-color: var(--box-bg-color);
        opacity: 0.9;
    }
    100% {
        opacity: 0.3;
    }
}
