.Chat {
    /* background-color: #f5f5f5; */
    margin-bottom: 500px;	
    border-radius: 4px;
    padding: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }

  :root {
    
  }

  .Chat .chat_container {
    position: relative;
    box-shadow: 0 0 10px -3px var(--color-light-green);
    border-radius: 15px;
    width: 85%;
    max-width: 600px;
    padding: 26px;
    margin: 50px auto 0 auto;
    min-height: 500px;
    max-height: 600px;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;

  }
  
  .Chat .chat_container .inner_chat_messages {
    display: flex;
    flex-direction: column;

    }

  .Chat .Message {
    display: flex;
    margin-bottom: 16px;
  }
  
  .Chat .Avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .Chat .Avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .Chat .Content {
    margin-left: 12px;
    margin-right: 12px;
    max-width: 70%;

  }
  
  .Chat .Text {
    font-size: 14px;
    line-height: 1.4;
    background-color: var(--color-dark-29);
    padding: 8px;
    border-radius: 4px;
    color: var(--color-dark);
    width: 100%;
  }
  
  .Chat .Time {
    font-size: 12px;
    color: #888;
    margin-top: 4px;
  }
  
  .Chat .play_button {
    width: 150px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #888;
    gap: 4px;
  }
  
  .Chat .play_button:hover {
    text-decoration: underline;
  }
  
  .Chat .align-right {
    /* justify-content: flex-end; */
    /* direction: rtl; */
  }

  .Chat .chat__conversation-panel {
    background-color: var(--color-dark-29);
    border-radius: 12px;
    padding: 0 1em;
    position: sticky;
    bottom: 0;
    margin: 0.5em 0 0;
    z-index: 2;
    bottom: -27px;
    height: 50px;
  }

 

  .Chat  .chat__conversation-panel__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    position: relative;
    gap: 10px;
  }
  
  .Chat  .chat__conversation-panel__container .cancel_record{
    position: absolute;
    right: 42%;
    color: white;
    background-color: var(--color-dark-31);
    padding: 6px 14px;
    border-radius: 14px;
    font-size: 12px;
    cursor: pointer;
    animation: fadeInRight 0.5s forwards;
  }
  .Chat .chat__conversation-panel__container .cancel_record.fade-out {
    animation: fadeOutRight 0.5s forwards;
}
  .Chat  .chat__conversation-panel__container .cancel_record:hover {
  color: var(--color-light-green);
  }
  @keyframes fadeInRight {
    from {
        right: 0%; /* start from the far right */
        opacity: 0; /* start with 0 opacity */
    }
    to {
        right: 42%; /* end at 42% from the right */
        opacity: 1; /* end with full opacity */
    }
}
  @keyframes fadeOutRight {
    from {
        right: 42%; 
        opacity: 1;
    }
    to {
        right: 0%; 
        opacity: 0; 
    }
}

  .Chat .chat__conversation-panel__input {
    width: 100%;
    outline: none;
    color: var(--color-dark);
    font-size: 15px;
    background: transparent;
    border: 0;
    font-family: "Lato", sans-serif;
    resize: none;
    max-height: 65px;
    height: 3em;
    overflow-y: auto;
    white-space: pre-wrap;
    padding: 0;

    position: relative;
    top: 13px;
  }
  .chat__conversation-panel__button:hover {
    transform: translateY(-1px); 
}

  .Chat .chat__conversation-panel__button {
    transition: transform 0.3s ease; 
    background-color: transparent;
    height: 30px;
    width: 30px;

    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
  }
  
  @keyframes typingDots {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }


  .Chat .loader_btn {
    width: 30px;
    height: 26.65px;
    display: inline-block;
    position: relative;
  }
  .Chat .loader_btn::after,
  .Chat .loader_btn::before {
    content: '';  
    box-sizing: border-box;
    width: 30px;
    height: 26.65px;
    border-radius: 50%;
    border: 2px solid var(--color-light-green);
    position: absolute;

    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }
  .Chat .loader_btn::after {
    animation-delay: 1s;
  }
  
  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  
  .Chat .typing .Content {
    width: 80px;
  }
  
  .Chat .typing .Text::after {
    content: "";
    display: inline-block;
    animation: typingDots 1.5s infinite steps(3);
  }
  

  .Chat .hidden {
    display: none;
  }


  .Chat .chat_container::-webkit-scrollbar {
    width: 9px;
  }
  
  .Chat .chat_container::-webkit-scrollbar-track {
    /* background-color: var(--color-white); */
    background-color: transparent;
    border-radius: 20px;
}

.Chat .chat_container::-webkit-scrollbar-thumb {
    background: #464646;
    border-radius: 20px;
}

.Chat .chat_container::-webkit-scrollbar-thumb:hover {
    background-color: #575757;
    cursor: pointer !important;
  }


  .delete_chat_history_btn {
    width: 200px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    /* background: var(--color-dark-29); */
    background: var(--color-dark-31);
    /* background: #313131; */
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
    direction: ltr !important;
   }
   
   .delete_chat_history_btn, .delete_chat_history_btn span {
    transition: 200ms;
   }
   

   .delete_chat_history_btn .text {
    transform: translateX(18px);
    /* color: white; */
    font-size: 13px;
    color: var(--color-dark);
    font-weight: 300;
    /* font-weight: bold; */
   }
   
   .delete_chat_history_btn .icon {
    position: absolute;
    border-left: 1px solid var(--color-light-green);
    transform: translateX(150px);
    height: 35px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   
   .delete_chat_history_btn svg {
    width: 15px;
    fill: var(--color-dark);
   }
   
   .delete_chat_history_btn:hover {
    background: var(--color-dark-31);
   }
   
   .delete_chat_history_btn:hover .text {
    color: transparent;
   }
   
   .delete_chat_history_btn:hover .icon {
    width: 150px;
    border-left: none;
    transform: translateX(25px);
   }
   
   .delete_chat_history_btn:focus {
    outline: none;
   }
   
   .delete_chat_history_btn:active .icon svg {
    transform: scale(0.8);
   }


   .Chat .d-none {
    display: none;
   }
/* 

   @media (max-width: 480px) {
    .Chat .chat__conversation-panel {
      width: 80%;
      margin: auto auto;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
   */

   .custom-audio-control {
    width: 140%; 
    margin: 10px auto;
    position: relative;
    right: 12%;

}

.custom-audio-control audio {
    transform: scale(0.8); 
    width: 100%;
    height: 40px;
    vertical-align: middle;
}



   .custom-audio-control-chat {
    width: 300px; 
    position: relative;
    left: 25px;
}

.custom-audio-control-chat audio {
    transform: scale(0.8); 
    width: 100%;
    height: 50px;
    vertical-align: middle;
}



.select-voices {
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}


@media only screen and (max-width: 750px) {
  .Chat .chat_container {
      width: 100%;
  }
  .custom-audio-control-chat {
    width: 240px; 
  }

  .custom-audio-control {
  width: 200% !important;
  }
  .custom-audio-control audio {
  width: 120% ;
  }


  .Chat  .chat__conversation-panel__container .cancel_record[data-lang="en"] {
    /* position: absolute; */
    /* left: 55%; */
    /* width: 30px; */
    position: absolute;
    right: 25% !important;
    width: 65px !important;
  }
  .Chat  .chat__conversation-panel__container .cancel_record[data-lang="not-en"] {
  /* position: absolute; */
    left: 25% !important;
    width: 65px !important;

 }
}
.Chat .StartRecordingBtn {
  position: absolute;
  transition: transform 0.3s ease;
  /* bottom: 40px !important; */
}

.StartRecordingBtn.dragging {
  cursor: grabbing;
}




.Chat .loader {
  width: 250px;
  height: 100px;
  box-shadow: 0px 20px 40px #ff4646, 0px -20px 40px #8949ff, 20px 0px 40px#ff07fb, -20px 0px 40px #ff07fb;
  border-radius: 10em;
  position: relative;
    animation: fade 2s ease-in-out, rotate 1s linear infinite; /* Added fade animation */

}


@keyframes fade {
  from {
    width: 0px;   /* Start with 0px width */
    height: 0px;  /* Start with 0px height */
    opacity: 0;   /* Start with 0 opacity */
  }

  to {
    width: 250px; /* End with 150px width */
    height: 100px; /* End with 150px height */
    opacity: 1;  /* End with 1 opacity (fully visible) */
  }
}



/* works only on a dark background with these paremeters*/

.chat_settings {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 8px;
  height: 36px;
  width: 120px;
  border: none;
  background: var(--color-dark-31);
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: -20px;
  margin-top: 30px;
}

.chat_settings .lable {
  line-height: 20px;
  font-size: 17px;
  color: var(--color-light-green);
  font-family: sans-serif;
  letter-spacing: 1px;
}

.chat_settings:hover {
  background: var(--color-dark-29);
}

.chat_settings:hover .svg-icon {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/*------ Settings ------*/
.auto_play_container {
  --color: #a5a5b0;
  --size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  cursor: pointer;
  font-size: var(--size);
  user-select: none;
  fill: var(--color);
  gap: 15px;
  margin-bottom: -30px;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
  overflow: hidden;
  max-height: 0; /* Initially collapsed */
  opacity: 0;
  border-radius: 6px;
  width: 260px;
  background-color: var(--color-dark-31);
  padding: 6px 10px;
}


.auto_play_container:hover {
  background-color: var(--color-dark-29);
}

.auto_play_container svg {
  width: 20px;
  height: 20px;
}

.auto_play_container p {
  color: var(--color-dark);
  font-size: 15px;
}
.auto_play_container .mute {
  /* position: absolute; */
  animation: keyframes-fill .5s;
}

.auto_play_container .voice {
  /* position: absolute; */
  display: none;
  animation: keyframes-fill .5s;
}

/* ------ On check event ------ */
.auto_play_container input:checked ~ .mute {
  display: none;
}

.auto_play_container input:checked ~ .voice {
  display: block;
}

/* ------ Hide the default checkbox ------ */
.auto_play_container input {
  /* position: absolute; */
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* ------ Animation ------ */
@keyframes keyframes-fill {
  0% {
    transform: rotate(0deg) scale(0);
    opacity: 0;
  }

  50% {
    transform: rotate(-10deg) scale(1.2);
  }
}



.openSettings {
  max-height: 100px; /* This value should be more than the content's actual height */
  opacity: 1;
  width: 260px;
}

.closeSettings {
  max-height: 0; 
  opacity: 0;
  display: none;
}



.change_teacher_language_btn {
  padding: 5px 10px;
  border-radius: 6px;
  outline: 0;
  border: none;
  color: var(--color-dark);
  background-color: var(--color-dark-31);
  margin-bottom: -30px;
}

.change_teacher_language_btn:hover {
  background-color: var(--color-dark-29);
}






.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: var(--color-white);

}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3fcd1;
  animation: pulse 1.5s infinite ease-in-out;
}


.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3fccc;
    box-shadow: 0 0 0 0 rgba(178, 252, 232, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #67fbc5;
    box-shadow: 0 0 0 10px rgba(178, 252, 183, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3fce7;
    box-shadow: 0 0 0 0 rgba(178, 252, 214, 0.7);
  }
}