.FirstTimeStep3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.teachers_container {
  display: flex;
  gap: 50px;
}
.teacher_card {
  width: 100%;
  max-width: 200px;
  min-width: 150px;
  height: 200px;
  background-color: #292929;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border: 2px solid rgba(7, 7, 7, 0.12);
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 10px 10px rgba(121, 172, 120, 0.5), 0px -10px 10px rgba(97, 130, 100, 0.5), 10px 0px 10px rgba(176, 217, 177, 0.5), -10px 0px 10px rgba(208, 231, 210, 0.5); /* Apply opacity to the box shadow */
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}
.FirstTimeStep3 .not_chosed {
opacity: 0.5;
}
.FirstTimeStep3 .chose_teacher {
  border: 3px solid white;
  transform: scale(1.05);
}
.FirstTimeStep3 .chose_teacher:active {
  transform: scale(0.95) ;

}


.FirstTimeStep3 .play_button {
justify-content: center;
margin-top: 10px;
margin-bottom: -10px;
gap: 4px;
}
.icon img{
  margin: 0 auto;
  width: 100%;
  height: 80px;
  max-width: 80px;
  background: linear-gradient(90deg, #79AC78 0%, #B0D9B1 40%, rgba(121, 172, 120, 0.28) 60%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease;
  background-position: 0px;
  object-fit: cover;
  background-size: 200px;
}

.icon svg {
  fill: white;
}

.teacher_card .title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.teacher_card .text {
  width: 80%;
  margin: 0 auto;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  color: white;
  font-weight: 200;
  letter-spacing: 2px;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease;
}

.teacher_card:hover {
  height: 270px;
}

.teacher_card:hover .text {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: 40px;
}

.teacher_card:hover .icon {
  background-position: -120px;
  transition: all 0.3s ease;
}

.teacher_card:hover .icon svg path {
  fill: url('#gradientColor');
  transition: all 0.3s ease;
}


.FirstTimeStep3 .typing-effect {
  white-space: nowrap;
  color: white;
  text-align: center;
  overflow: hidden;
  margin: 100px;
  border-right: 2px solid white; /* Simulate cursor */
  animation: typingText 2s steps(32, end) forwards; /* Adjust duration and steps as needed */
}

.FirstTimeStep3 .typing-effect.typing-complete {
  border-right: none; /* Remove the border-right when typing is complete */
}

@keyframes typingText {
  from {
    width: 0;
  }
  to {
    width: 75%;
  }
}

.FirstTimeStep3 .none {
  /* display: none; */
  opacity: 0;
}

.FirstTimeStep3 .show_teacher {
  position: relative;
  animation: upperFade 1s ease-in-out;
  margin-top: 40px;
    /* Added fade animation */
}

@keyframes upperFade {
  0% {
    opacity: 0;
      bottom: -100px;
    }
    100% {
      opacity: 1;
    bottom: 0;
  }
}


.FirstTimeStep3 .btn_start {
  margin-top: 120px !important;
}

.FirstTimeStep3 .teacher_card:hover {
  margin-bottom: -70px !important;
}

@media only screen and (max-width: 767px) {
  .teachers_container {
      align-items: center;
  }

  .teacher_card {
      width: 80%;
      margin: 10px auto;
  }

  .FirstTimeStep3 .btn_start {
      margin-top: 120px !important;
  }

  .FirstTimeStep3 .typing-effect {
      margin: 50px;
      font-size: 16px;
  }
  .loader.up {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .teacher_card {
      width: 45%;
  }
  .loader.up {
    width: 90%;
  }

  .FirstTimeStep3 .btn_start {
      margin-top: 80px !important;
  }

  .FirstTimeStep3 .typing-effect {
      margin: 70px;
      font-size: 18px;
  }
}

@media only screen and (min-width: 1025px) {
}


@media only screen and (max-width: 450px) {
  .teacher_card {
    min-width: 50px;
    max-width: 130px;
  }
  .teacher {
    width: 25%;
  }
  .teachers_container {
    /* flex-direction: column; */
      align-items: center;
  }
}