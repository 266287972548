.Video {
    width: 100%;
    margin-bottom: 250px ;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Video .video_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto;
    
}
.Video iframe {
    border-radius: 10px;
}


@media only screen and (max-width: 680px) {
.Video{
    margin-bottom: 450px;
}

.Video .video_container{
    width: 90%;
}
}