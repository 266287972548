.PageNotFound {
	margin-bottom: 450px;
    margin-top: 150px;
}

.pageNotFound_Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.PageNotFound h1{
    display: flex;
    justify-content: center;
    margin: 0;
}

.notFoundNumber{
    color: var(--color-light-dark);
    font-size: 140px;
}

.notFountText{
    color: var(--color-light-green);
    font-size: 60px;

}

.PageNotFound button {
    width: 200px;
    margin: 50px auto;
    background-color: var(--color-white);
    border-radius: 20px;
    border: none;
    padding: 15px ;
    cursor: pointer;
    outline: 3px solid var(--color-light-green);
    font-size: 16px;
    font-weight: 600;
    color: var(--color-dark);
}

.PageNotFound button:hover {
    transition: 0.2s;
    background-color: var(--color-light-green);
    color: var(--color-white);
}


@media only screen and (max-width: 680px) {
 .notFoundNumber{
    font-size: 60px;
 }

 .notFountText{
    font-size: 40px;
 }
 .pageNotFound_Container{
    margin-bottom: -350px;
 }
}