.rc_ExtenstionData {
    background-color: var(--box-bg-color);
    border-radius: var(--box-border-radius);
    box-shadow:  var(--box-box-shadow);
}

.rc_ExtenstionData:hover {
    box-shadow:  var(--box-box-shadow-hover)
}

.rc_ExtenstionData .rc_component_title {
    font-weight: var(--box-title-font-weight);
    color: var(--color-light-green);
    font-size: var(--box-title-font-size);
}

.rc_ExtenstionData .rc_info_line p {
    color: var(--box-info-p-color);
    font-weight: var(--box-info-p-font-weight);
}


.rc_ExtenstionData .rc_info_line span {
    color: var(--box-info-span-color);
}

.rc_ExtenstionData .icon {
    width: 18px;
    height: 18px;
    /* bottom: 3px; */
    /* position: relative; */
}



.loading_container {
    background-color: var(--box-bg-color) !important;
    opacity: 0.5;
    animation: pulse 1.4s ease-in-out infinite;
}

.loading_container > * {
    opacity: 0 !important;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        background-color: var(--box-bg-color);
        opacity: 0.9;
    }
    100% {
        opacity: 0.3;
    }
}



.rc_ExtenstionData .loader {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    animation: rotate 3s linear infinite
  }
  .rc_ExtenstionData .loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid var(--color-dark);
    animation: prixClipFix 2s linear infinite ;
  }

  @keyframes rotate {
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }