body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow: unset !important;
    overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* --save-words-lists-line-margin-bottom: 10px; */
  --save-words-lists-line-padding-bottom: 10px;
}

.Layout[data-theme='dark-mode'] {
  /* --color-light-green: #2ccd85; */
  --color-dark: #252525;
  --color-light-dark: #423f3fce;
  --color-white: #fff;
  

  /*chat component */
  --color-dark-29: #f3f3f3;
  --color-dark-31: #eeeeee;

  /* manager dashboard */
  --box-box-shadow: 3px 1px 10px 0px #0000001f;
  --box-box-shadow-hover: 3px 1px 10px 0px #00000025;
  --box-bg-color: white;
  --box-info-p-color: #292929;
}

.Layout[data-theme='light-mode'] {
  /* --color-light-green: #2ccd85; */
  --color-dark: #c9c9c9;
  --color-light-dark: #a1a1a1ce;
  --color-white: #252525;


    /*chat component */
  --color-dark-29: #292929;
  --color-dark-31: #313131;

    /* manager dashboard */
    --box-box-shadow: 3px 1px 10px 0px #ffffff13;
    --box-box-shadow-hover: 3px 1px 10px 0px #ffffff18;
    --box-bg-color: #202020;
    --box-info-p-color: #e0e0e0c9;


}