.FirstTimeStep4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.FirstTimeStep4 h1 {
  color: var(--color-dark);
  margin-top: 100px;
  margin-bottom: 50px;
}