.card {
    width: 150px;
    height: 150px;
    background-image: url("./memory-card-cover-b.png"); 
    background-size: cover;
    font-size: 30px;
    cursor: pointer;
    transition: 0.4s;
    transform-style: preserve-3d;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    overflow: hidden; /* Prevent text from being visible when flipped */
    position: relative; /* Add this to properly position the card-inner */
    box-shadow: 0 0 10px 0px var(--color-light-green);
    border-radius: 15px;
    color: var(--color-light-green);
  }



  .Card img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}
  
  .card:hover {
    box-shadow: 0 0 10px 1px var(--color-light-green);
  }

  
  .flipped {
    transform: rotateY(360deg);
    background-color: var(--color-light-green);
    color: white;
    background-image: url("");
  }

  .card_inner {
    font-size: 22px;
  }
  


@media only screen and (max-width: 680px) {


  .Card {
      width: calc((100% - (2 * 20px)) / 3) ;
      height: calc(((100% - (2 * 20px)) /3) * 1.5);
      font-size: 12px;
      background-size: cover;
      margin-bottom: 25px;
      display: inline-block;
    
  }

  .card{
    font-size: 24px;
  }
  .Card button {
      width: 100px;
      height: 100px;
  }

  .Card img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .flipped {
      background-image: url("");
  }

  .card_inner {
    font-size: 16px;
  }
  
}
