.Home {
	display: flex;
    flex-direction: column;
}

.Layout .svg1 {
    position:  absolute;
    width: 700px;
    right: 0%;
    top: 15%;
    z-index: 0;
}
.Layout .svg2 {
    position:  absolute;
    width: 600px;
    left: 0%;
    top: 75%;
    z-index: 0;
}

@media only screen and (max-width: 1200px) {
    .Layout .svg1 {
        display: none;
    }
    .Layout .svg2 {
        display: none;
    }
}
