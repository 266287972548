.BlogArticle3 {
    margin-bottom: 350px;
    direction: rtl;
}



@media only screen and (max-width: 850px) {
    .BlogArticle3 {
        margin-bottom: 150px;
    }
}

@media only screen and (max-width: 550px) {
    .BlogArticle3 {
        margin-bottom: 50px;
    }

}

@media only screen and (max-width: 680px) {
    .BlogArticle3 {
        margin-bottom: 500px;
    }

}