.PodcastsSection {
	
}
.PodcastsSection .podcasts_title {
    font-size: 40px;
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 65px;
}

.PodcastsSection .podacsts_boxes {
    font-size: 18px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap:  35px 10px; /* Adds a 20px gap between grid elements */
    max-width: 930px;
    margin: 0 auto;
  }
  

.PodcastsSection .podacsts_boxes .pad-left {
    padding: 0 80px 0 20px;
}

.PodcastsSection .podacsts_boxes .pad-right {
    padding: 0 20px 0 80px;
}

.PodcastsSection .podacsts_boxes .podacsts_box {
    width: 330px ;
    /* padding: 0 20px 0 80px; */
    height: 120px ;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    gap: 20px;
    color: var(--color-dark);
    box-shadow: 0 0 6px -2px var(--color-dark);
    cursor: pointer;
}

.PodcastsSection .podacsts_boxes .podacsts_box:hover {
    transition: 0.2s;
    box-shadow: 0 0 6px -1px var(--color-dark);
}

.PodcastsSection .podacsts_boxes .podcast_icon {
font-size: 65px;
display: flex;
justify-content: center;
align-items: center;
}
.PodcastsSection .podacsts_boxes .podacsts_box .podcast_name_topic {
font-size: 22px;
display: flex;
flex-direction: column;
justify-content: center;
}

.PodcastsSection .podacsts_boxes .podacsts_box .text-left {
text-align: left;
}

.PodcastsSection .podacsts_boxes .podacsts_box .text-right {
text-align: right;
}

.PodcastsSection .podacsts_boxes .podacsts_box .podcast_topic {
font-size: 14px;
color: var(--color-light-dark);
}

@media screen and (max-width: 925px) {
    .PodcastsSection .podacsts_boxes {
        font-size: 18px;
        gap:  35px 10px; /* Adds a 20px gap between grid elements */
        margin: 0 auto;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }
      .PodcastsSection .podacsts_boxes .podacsts_box {
        width: 50vw;
        justify-content: end;
    }
    .PodcastsSection .podacsts_boxes .podacsts_box {
        width: 325px;
        height:97px
    }

.PodcastsSection .podacsts_boxes .pad-left {
    padding: 0 0 0 45px;

}
.PodcastsSection .podacsts_boxes .pad-right {
    padding: 0 45px 0 0;

}
    .PodcastsSection .podacsts_boxes .podacsts_box .podcast_name_topic {
    font-size: 18px;
    }

    .PodcastsSection .podacsts_boxes .podacsts_box .podcast_topic {
        font-size: 14px;
    }

    .PodcastsSection .podacsts_boxes .podcast_icon {
        font-size: 45px;
    }
  
}