.subscribers_modal_line {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 18px;
  }

    
  .subscribers_modal_line  tbody tr {
    border-top: 1px solid var(--color-dark-31);
  }
  .subscribers_modal_line  td {
    padding: 8px 0;
    font-weight: normal;
  }
  .subscribers_modal_line   th {
    font-weight: bold;
  }


.subscribers_modal_line  .delete-container .delete {
    display: none; /* Initially hidden */
    cursor: pointer;
}
  
.subscribers_modal_line  .delete-container:hover .delete {
    display: block; /* Show on hover */
  }
  
.subscribers_modal_line  .delete-container:hover .delete_outline {
    display: none; /* Hide on hover */
  }
  

.subscribers_modal_line  .seen-container .seen {
    display: none; /* Initially hidden */
    cursor: pointer;
}
  
.subscribers_modal_line  .seen-container:hover .seen {
    display: block; /* Show on hover */
  }
  
.subscribers_modal_line  .seen-container:hover .seen_outline {
    display: none; /* Hide on hover */
  }
  


#subscribers_description {
    padding: 0;
}


.subcribers_desc_archive_line {
    margin-bottom: 25px;
}
  .btnArchive {
    padding: 7px 16px;
    border-radius: 15px;
    border: none;
    font-size: 12px;
    box-shadow: 0 0 6px -2px rgba(0, 0, 0, 0.534);
  }
  

  .btnArchive:hover {
    outline: 1px solid var(--color-light-green);
}

.unarchive {
    background-color: var(--color-light-green);
}
.unarchive:hover {
      outline: 1px solid black;
  }