.Games {
	margin-bottom: 400px;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 150px;
}

.Games .games_top_container {
    width: 80%;
    height: 160px;
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.Games .games_top_container .top_text {
    color: var(--color-light-green);
    font-size: 40px;
}

.Games .games_top_container .background_top_image {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    border-radius: 25px;
    object-fit: cover;
    opacity: 0.6;
    box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.384);
}


.Games .games_container {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.Games .games_container .game_box {
    width: 30%;
    height: 24vw;
    position: relative;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    cursor: pointer;
}

.Games .game_box h4 {
    color: var(--color-dark);
    text-align: center;
    position: relative;
    bottom: 50px;
}

.Games .games_container .game_box p {
    font-weight: bold;
    line-height: 20%;
    z-index: 1;
}

.Games .games_container .game_box p {
    z-index: 1;
}

.Games .games_container .game_box img {
    box-shadow: 0 0 10px 0px var(--color-light-green);
    border-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position:absolute;
    z-index: 0;
}

.Games .games_container .game_box:hover > img {
    box-shadow: 0 0 10px 1px var(--color-light-green);
}


.game_name{
    font-size: 50px;
}

@media only screen and (max-width: 1000px) {
    .Games {
        margin-bottom: 100px;
        gap: 50px;
        margin-top: 50px;
    }
}
@media only screen and (max-width: 680px) {
    .Games {
        margin-bottom: 600px !important;
    }
    .Games .games_container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 70px;
    }
    
    .Games .games_container .game_box {
        height: 180px;
        width: 180px;
    
    }
    
.Games .game_box h4 {
    font-size: 18px;
    bottom: 35px;
}
    .Games {
        margin-bottom: 100px;
        margin-top: 0px;
        gap: 0px;
    }

    .Games .games_top_container .top_text {
        font-size: 20px;
    }
    
    
  }