.rc_ImagesSection {
    background-color: var(--box-bg-color);
    border-radius: var(--box-border-radius);
    box-shadow:  var(--box-box-shadow);
}


.rc_ImagesSection:hover {
    box-shadow:  var(--box-box-shadow-hover)
}

.rc_ImagesSection .rc_component_title {
    font-weight: var(--box-title-font-weight);
    color: var(--color-light-green);
    font-size: var(--box-title-font-size);
}

.rc_ImagesSection .rc_info_line p {
    color: var(--box-info-p-color);
    font-weight: var(--box-info-p-font-weight);
}

.rc_ImagesSection .rc_info_line span {
    color: var(--box-info-p-color);
    font-size: 15px;
}

.rc_ImagesSection .icon {
    width: 17px;
    height: 17px;
}



.loading_container {
    background-color: var(--box-bg-color) !important;
    opacity: 0.5;
    animation: pulse 1.4s ease-in-out infinite;
}

.loading_container > * {
    opacity: 0 !important;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        background-color: var(--box-bg-color);
        opacity: 0.9;
    }
    100% {
        opacity: 0.3;
    }
}












.rc_ImagesSection ::-webkit-scrollbar {
    height: 10px;
  }
  
  /* Track */
  .rc_ImagesSection  ::-webkit-scrollbar-track {
    background: var(--color-dark-31);
    border-radius: 6px;
  }
  
  .rc_ImagesSection  ::-webkit-scrollbar-thumb {
    background: var(--color-light-green); 
    border-radius: 6px;
}

  

.rc_ImagesSection .container {
    max-width: 100%;
    display: flex;
    max-height: 105px;
    gap: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.rc_ImagesSection h6 {
    text-align: center;
}

.rc_ImagesSection h6 small {
    display: block;
    font-size: 12px;
    padding: 4px 0 ;
    color: gray;
}
.rc_ImagesSection .avatar-upload {
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}
.rc_ImagesSection .avatar-upload .avatar-edit {
    position: absolute;
    right: -6px;
    z-index: 1;
    top: 5px;
}
.rc_ImagesSection .avatar-upload .avatar-edit input {
    display: none;
}
.rc_ImagesSection .avatar-upload .avatar-edit input + label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}
.rc_ImagesSection .avatar-upload .avatar-edit input + label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.rc_ImagesSection .avatar-upload .avatar-preview {
    width: 87px;
    height: 87px;
    position: relative;
    border-radius: 100%;
    border: 6px solid var(--color-white);
    /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 2px 6px -3px var( --box-info-p-color);
}
.rc_ImagesSection .avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
