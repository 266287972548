.PrivacyPolicy {
    display: flex;
    flex-direction: column;  
    align-items: center;
    margin: 0 auto;  
    width: 90%;
    margin-bottom: 250px;
    direction: ltr !important;
}   

.PrivacyPolicy h1 {
    color: var(--color-dark);
}

.PrivacyPolicy p {
    color: var(--color-dark);
}

@media only screen and (max-width: 682px) {
.PrivacyPolicy {
    margin-bottom: 500px;
}
}