.Quiz {
    margin-bottom: 550px;
}


.Quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    padding: 20px;
    }
    
    .Quiz  h1 {
    font-size: 40px;
    color: var(--color-dark);
    margin-bottom: 0px;
  }
  
  .Quiz  p {
      color: var(--color-dark);
      margin: 20px 0;

      text-align: center;
    }
    
    .Quiz .time_left_p {
      color: var(--color-dark);
      margin-top: 20px;
      margin-bottom: -10px;
    }
    
    .Quiz h2 {
      color: var(--color-dark);
    font-size: 60px;
    margin-top: 20px;
    margin-bottom: 30px;
    }
    
    .Quiz .answer_button_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .Quiz button {
    background-color: var(--color-light-green);
    color: white;
    font-size: 18px;
    height: 55px;
    width: 225px;
    margin: 10px 5px;
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.342);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    }
    
    .Quiz button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    }

    .Quiz progress {
        margin: 20px;
        width: 250px;
        height: 24px;
        appearance: none;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2) inset;
      }
      
      .Quiz progress::-webkit-progress-value {
        background-color: var(--color-light-green);
        border-radius: 12px;
        transition: width 0.2s ease-in-out;
      }
      
      .Quiz progress[value]::-webkit-progress-bar {
        background-color: #eee;
        border-radius: 12px;
      }
      
      .Quiz progress[value="10"]::-webkit-progress-value {
        width: 100%;
      }
      


      .Quiz button.correct {
        border: 3px solid green;
      }
      
      .Quiz button.incorrect {
        border: 3px solid red;
      }
   
      @media only screen and (max-width: 680px) {
        .answer_button_container button {
          width: 40vw;
      }
      .Main{
        margin-bottom: -413px;
      }
      }