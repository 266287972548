.MemoryGame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 750px;
    margin: 50px auto 300px auto;
}

.MemoryGame .game-board{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px;
}

.MemoryGame .game-over {
    position: absolute;
    top: 76px;
    display: flex;
    width: 1000px;
    height: 500px;
    align-items: center;
}

.MemoryGame .game-over canvas {
    z-index: 0 !important;
}

.MemoryGame .total-moves {
    font-size: 35px;
    color: var(--color-dark);
}

.MemoryGame .reset-button {
    margin-top: 10px;
    width: 50%;
    height: 50px;
    font-size: 25px;
    border-radius: 15px;
    background-color: var(--color-dark);
    color: var(--color-white);
    cursor: pointer !important;
}



@media only screen and (max-width: 680px) {
    .MemoryGame {
        max-width: 350px;
        margin-bottom: 500px;
    }

    .MemoryGame .game-board {
        gap: 20px;
    }

}
