.SpecificArticle {
	margin-bottom: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

.SpecificArticle .specificArticle_top_container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
    margin-bottom: 40px;
    color: var(--color-dark);
}

.SpecificArticle .loader_container p {
    text-align: right;
    direction: rtl;
}
.SpecificArticle .timeText {
    justify-content: center !important;
}
.SpecificArticle .loader_container {
    display: flex;
    /* flex-direction: column; */
    justify-content: end;
    align-items: center;
}
.SpecificArticle .loader_container img {
    width: 7%;
    position: relative;
    bottom: 20px;
}


.SpecificArticle .article_main_container {
    max-width: 800px;
    width: 100%;
    border: 1px solid var(--color-dark);
    border-radius: 15px;
    padding: 30px;
    overflow-y: auto;
    background-color: var(--color-white);
    box-shadow: 0 4px 6px var(--color-dark);
}




.SpecificArticle .article_main_container h2 {
    font-size: 28px;
    color: var(--color-dark);
    margin-bottom: 15px;
    font-weight: bold;
    border-bottom: 2px solid var(--color-light-green);
    display: inline-block;
    padding-bottom: 5px;
}


.SpecificArticle .article_main_container p {
    font-size: 18px;
    color: var(--color-light-dark);
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: justify;
}


   @media only screen and (max-width: 680px) {
    .SpecificArticle .article_main_container {
        max-width: calc(100% - 40px);
        padding: 20px;
    }

    .SpecificArticle .article_main_container h2 {
        font-size: 24px;
    }

    .SpecificArticle .article_main_container p {
        font-size: 16px;
    }

    .SpecificArticle .specificArticle_top_container {
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 480px) {
    .article_main_container{
        margin-bottom: -200px;
    }
    .SpecificArticle .article_main_container {
        width: 88%;
        margin: 0 auto; /* Centers the div horizontally */
      }
    .SpecificArticle .specificArticle_top_container {
        font-size: 1em;
    }

    .SpecificArticle .article_main_container h2 {
        font-size: 20px;
    }

    .SpecificArticle .article_main_container p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 865px) {
    .SpecificArticle{
        margin-bottom: 550px;
    }
    .article_main_container{
        margin-bottom: -00px;
    }

    .SpecificArticle .article_main_container {
        /* width: 88%; */
        margin: 0 auto; /* Centers the div horizontally */
      }
    .SpecificArticle .specificArticle_top_container {
        font-size: 1em;
    }

    .SpecificArticle .article_main_container h2 {
        font-size: 26px;
    }

    .SpecificArticle .article_main_container p {
        font-size: 18px;
    }

    .SpecificArticle .loader_container p {
        font-size: 15px;
    }
}


.highlighted {
    background-color: #FFF798 !important; 
    color: black;
  }