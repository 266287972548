.Dashboard {
margin-bottom: 200px;	
display: flex;
direction: rtl;
flex-direction: column;
margin-top:110px;
}

  
  
.Dashboard .dashboard_top_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    text-align: center;
}

.Dashboard .dashboard_top_container .dashboard_top_box {
    max-width: 310px;
    max-height: 120px;
    height: 11vw;
    width: 20vw;
    background-color: var(--color-white);
    padding: 20px 25px;
    box-shadow: 0 0 10px -1px var(--color-dark);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 22px;
    gap: 27px;
    position: relative;
    bottom: 0;
}

.Dashboard .dashboard_top_container .dashboard_top_box:hover {
    transition: 0.2s;
    bottom: 5px;
}

.Dashboard .dashboard_top_container .dashboard_top_box .icon_arrow {
    font-size: 28px;
}
.Dashboard .dashboard_top_container .dashboard_top_box .icon_box {
    font-size: 45px;
}
.Dashboard .dashboard_top_container .saved_words_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* padding-top: 15px; */
}
.Dashboard .dashboard_top_container .dashboard_top_box .saved_text {
    font-size: 18px;
    font-weight: bold;
    height: 30px;
    color: var(--color-dark);
    margin: 0 auto;
}
.Dashboard .dashboard_top_container .dashboard_top_box .game_text {
    font-size:18px;
    font-weight: bold;
    color: var(--color-dark);
}
.Dashboard .dashboard_top_container .dashboard_top_box .saved_words_count {
    font-size: 28px;
    font-weight: bold;
    position: relative;
    bottom: 5px;
    color: var(--color-dark);
}
.Dashboard .dashboard_top_container .dashboard_top_box span {
    color: var(--color-light-green);
}


.Dashboard .dashboard_bottom_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    gap: 10vw;
}

.Dashboard .dashboard_bottom_container .banner_dashboard img {
    width: 100%; 
    max-width: 300px ;       
}

.Dashboard .dashboard_bottom_container .save_words_list_container {
    padding: 25px;
    max-width: 450px;
    width: 35vw;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    font-size: 18px;
    background-color: var(--color-white);
    justify-content: space-between;
    box-shadow: 0 0 10px 0px var(--color-light-green);
    
}

.Dashboard .dashboard_bottom_container .save_words_list_container.animating {
    animation: shadow-box 2s ease-out;
}

@keyframes shadow-box {
    0% {
        box-shadow: 0 0 10px 0px var(--color-light-green);
    }
    20% {
        box-shadow: 0 0 10px 5px var(--color-light-green);
    }
    40% {
        box-shadow: 0 0 10px 0px var(--color-light-green);
    }
    60% {
        box-shadow: 0 0 10px 5px var(--color-light-green);
    }
    80% {
        box-shadow: 0 0 10px 0px var(--color-light-green);
    }
    100% {
        box-shadow: 0 0 10px 0px var(--color-light-green);
    }
}

.Dashboard .dashboard_bottom_container .save_words_lists_line {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-bottom:1px solid lightgray ;
    color: var(--color-dark);
}

.Dashboard .translate_saved_word {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.Dashboard .notLogged_dashboard_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.Dashboard .notLogged_dashboard_container p {
color: var(--color-dark);
}

@media only screen and (max-width: 1000px) {
    .Dashboard {
        margin-bottom: 30px;
    }
    .Dashboard .dashboard_top_container {
        width: 100%;
    }
    
    .Dashboard .dashboard_top_container .dashboard_top_box {
    width: 22vw;
    height: 10vw;
    }

    
.Dashboard .dashboard_bottom_container .banner_dashboard img {
    width: 300px;        
}
.Dashboard .dashboard_bottom_container {
gap: 5vw;
}
.Dashboard .dashboard_bottom_container .save_words_list_container {
width: 30vw ;
}
}

@media only screen and (max-width: 950px) {
    .Dashboard {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .Dashboard .dashboard_top_container {
    flex-direction: column;
    width: 100%;
    }
    .Dashboard .dashboard_top_container .dashboard_top_box .saved_text {
        position: relative;
        top: 10px;
    }
    
    .Dashboard .dashboard_top_container .dashboard_top_box {
    width: 70vw;
    height: 22vw;
    margin-bottom: 25px;

    }

    .Dashboard .dashboard_bottom_container {
    flex-direction: column;
    margin: 30px auto;
    gap: 30px;
    width: 100%;

    }

    .Dashboard .dashboard_bottom_container .save_words_list_container {
        width: 93% ;
    }
}
.saved_words_inner {
    display: flex;
    gap: 6px;
    flex-direction: column;
    justify-content: center;
  }


@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .Dashboard .dashboard_top_container .dashboard_top_box .saved_text {
        height: 50px;
    }
}
@media only screen and (min-width: 681px) and (max-width: 1511px) {
    
  .saved_words_inner {
    flex-direction: column;
    gap: 10px; 
  }
  }
  @media screen and (min-width: 280px) and (max-width: 1200px) {
    .saved_text {
      position: relative;
      bottom: 10px;
    }

    .Dashboard .dashboard_top_container .dashboard_top_box .saved_text {
        height: 30px;
    }
  }

  @media only screen and (max-width: 680px) {

      .Dashboard {
        margin-bottom: 500px;
    }

    /* .dashboard_top_box p {
        font-size: 18px !important;
    } */
    .dashboard_top_box {
        height: 100px !important;
    }
    
    .Dashboard .dashboard_top_container .dashboard_top_box .saved_text {
        height: 50px;
    }


    .Dashboard .dashboard_bottom_container .save_words_list_container {
        padding: 25px 10px;
    }

}
