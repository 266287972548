.FirstTimeStep2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FirstTimeStep2 .radio-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 80px;
}

.FirstTimeStep2 .radio-inputs > * {
  margin: 20px;
  text-align: center;

}

.FirstTimeStep2 .radio-input:checked + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;
}

.FirstTimeStep2 .radio-input:checked + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #2260ff;
  border-color: #2260ff;
}

.FirstTimeStep2 .radio-input:checked + .radio-tile .radio-icon svg {
  fill: #2260ff;
}

.FirstTimeStep2 .radio-input:checked + .radio-tile .radio-label {
  color: #2260ff;
}

.FirstTimeStep2 .radio-input:focus + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.FirstTimeStep2 .radio-input:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.FirstTimeStep2 .radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 186px;
  min-height: 100px;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.FirstTimeStep2 .radio-tile:before {
  content: "";
  /* position: absolute; */
  display: block;
  width: 0.75rem;
  height: .75rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  position: relative;
}

.FirstTimeStep2 .radio-tile:hover {
  border-color: #2260ff;
}

.FirstTimeStep2 .radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.FirstTimeStep2 .radio-tile img {
position: absolute;
width: 100%;
height: 100%;
object-fit: contain;
}



.FirstTimeStep2 .radio-label {
  color: var(--color-dark);
  transition: 0.375s ease;
  font-weight: 500;
  margin-top: 5px;
  text-align: center;
  font-size: 15px;
}
.FirstTimeStep2 .radio-input {
  height: 1px;
  width: 1px;
}




.FirstTimeStep2 .typing-effect {
  white-space: nowrap;
  color: white;
  text-align: center;
  overflow: hidden;
  margin: 100px 130px 50px 130px;
  border-right: 2px solid white; /* Simulate cursor */
  animation: typingText 2s steps(35, end) forwards; /* Adjust duration and steps as needed */
}

.FirstTimeStep2 .typing-effect.typing-complete {
  border-right: none; /* Remove the border-right when typing is complete */
}

@keyframes typingText {
  from {
    width: 0;
  }
  to {
    width: 95%;
  }
}

.FirstTimeStep2 .none {
  /* display: none; */
  opacity: 0;
}


.FirstTimeStep2 .show_languages {
  position: relative;
  animation: upperFade 1s ease-in-out;
    /* Added fade animation */
}

@keyframes upperFade {
  0% {
    opacity: 0;
      bottom: -100px;
    }
    100% {
      opacity: 1;
    bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .FirstTimeStep2 .radio-tile {
    border-radius: 50%;
    width: 80px ;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    margin-bottom: 20px; /* adjust as needed */
  }
}

@media only screen and (max-width: 767px) {
  .FirstTimeStep2 .typing-effect {
    margin: 20px 10px; /* adjust as needed */
    font-size: 16px; /* adjust as needed */
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .FirstTimeStep2 .radio-tile img {
    border-radius: 50%;
    position: absolute;
    /* height: auto; preserve aspect ratio */
  }

  .FirstTimeStep2 .typing-effect {
    white-space: normal;
    width: 90%;
  }

  label{
    width: 150px;
  }
}
