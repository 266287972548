.FirstTimeStep1 {
  height: 50vh;
  /* position: relative; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  flex-direction: column;
}

.FirstTimeStep1 .loader {
  width: 550px;
  height: 80px;
  box-shadow: 0px 20px 20px rgba(99, 153, 98, 0.5), 0px -20px 20px rgba(81, 109, 83, 0.5), 20px 0px 20px rgba(190, 226, 191, 0.5), -20px 0px 20px rgba(208, 231, 210, 0.5); /* Apply opacity to the box shadow */
  border-radius: 10em;
  position: absolute;
  opacity: 1;
  animation: fade 2.5s ease-in-out; /* Added fade animation */
  display: flex;
  justify-content: center;
  align-items: center;
}

.FirstTimeStep1 .typing-effect {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FirstTimeStep1 .typing-effect h1 {
  color: white;
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid white; /* Simulate cursor */
  animation: typing 1s steps(10, end) forwards;
  text-align: center;
}

@keyframes fade {

  0% {
      width: 0px;
      height: 0px;
      opacity: 0;
      box-shadow: 0px 20px 40px rgba(99, 153, 98, 0), 0px -20px 40px rgba(81, 109, 83, 0), 20px 0px 40px rgba(190, 226, 191, 0), -20px 0px 40px rgba(208, 231, 210, 0);
      
  }

  75% {
      width: 550px;
      height: 80px;
      opacity: 1;
      box-shadow: 0px 20px 40px rgba(99, 153, 98, 1), 0px -20px 40px rgba(81, 109, 83, 1), 20px 0px 40px rgba(190, 226, 191, 1), -20px 0px 40px rgba(190, 226, 191, 1);
  }

  95% {
      width: 550px;
      height: 80px;
      opacity: 1;
      box-shadow: 0px 20px 40px rgba(81, 109, 83, 1), 0px -20px 40px rgba(99, 153, 98, 1), 20px 0px 40px rgba(190, 226, 191, 1), -20px 0px 40px rgba(190, 226, 191, 1);
  }

  100% {
      width: 550px;
      height: 80px;
      opacity: 1;
      box-shadow: 0px 20px 20px rgba(99, 153, 98, 0.5), 0px -20px 20px rgba(81, 109, 83, 0.5), 20px 0px 20px rgba(190, 226, 191, 0.5), -20px 0px 20px rgba(190, 226, 191, 0.5);
  }
}

@keyframes typing {
  0% {
      width: 0;
  }
  100% {
      width: 100%;
  }
}

.none {
  display: none ;
}
.none_opacity {
  opacity: 0 !important;
}

.FirstTimeStep1 .up {
  position: relative;
  bottom: 100px;
  animation: upper 0.5s ease-in-out; /* Added fade animation */
}

@keyframes upper {
  0% {
      bottom: -30px;
    }
    100% {
    bottom: 100px;
  }
}


 .reveal {
  animation: fadeOpacity 1.5s ease-in-out !important; /* Added fade animation */

}

@keyframes fadeOpacity {
  0% {
      opacity: 0;

  }
  100% {
    opacity: 1;
  }
}




.btn_start {
  height: 55px;
  width: 175px;
  /* margin: auto; */
  align-items: center;
  border-radius: 50px;
  border: none;
  text-align: center;
  color: transparent;
  text-shadow: 0 0 0 rgb(255, 255, 255);
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 50ms ease;
  background: linear-gradient(90deg, #79AC78, rgb(121, 172, 120), #79AC78, #618264);
  color: #ffffff;
  text-shadow: var(--color-light-green) 0px 0px 20px;
  background-size: 400%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-family: "Montserrat", sans-serif;
}

.btn_start:hover {
  -webkit-box-shadow: 0px 0px 15px 0px #618264;
  -moz-box-shadow: 0px 0px 15px 0px #618264;
  box-shadow: 0px 0px 15px 0px #618264;
  animation: animate 8s ease infinite;
  transition: box-shadow, 0.4s;
  border: none;
  font-size: 19px;
  transform: scale(1.05, 1.05);
  background: linear-gradient(90deg, #618264, #79AC78, rgb(121, 172, 120), #79AC78);
  background-size: 400%;
  animation: animate 7s cubic-bezier(0.75, 0.75, 0.75, 0.75) infinite,
}

.btn_start:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}



.fade_out_all {
  animation: fadeOut 1.5s ease-in-out !important; /* Added fade animation */
  opacity: 0;
  position: relative;
  bottom: 100px
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    bottom: 0px
  }
  
  50% {
    bottom: 100px;
    opacity: 0;
  }
  100% {
    opacity: 0;
    bottom: 100px
  }
}


@media only screen and (max-width: 767px) {
  h1 {
    font-size: 18px !important;
    margin: 0 !important;
  }





  .FirstTimeStep1 .loader {
    animation: fadeMobile 2.5s ease-in-out; /* Added fade animation */
    width: 80%;
    height: 50px;
  }
  @keyframes fadeMobile {

    0% {
        width: 0px;
        height: 0px;
        opacity: 0;
        box-shadow: 0px 20px 40px rgba(99, 153, 98, 0), 0px -20px 40px rgba(81, 109, 83, 0), 20px 0px 40px rgba(190, 226, 191, 0), -20px 0px 40px rgba(208, 231, 210, 0);
        
    }
  
    75% {
        width: 80%;
        height: 50px;
        opacity: 1;
        box-shadow: 0px 20px 40px rgba(99, 153, 98, 1), 0px -20px 40px rgba(81, 109, 83, 1), 20px 0px 40px rgba(190, 226, 191, 1), -20px 0px 40px rgba(190, 226, 191, 1);
    }
  
    95% {
        width: 80%;
        height: 50px;
        opacity: 1;
        box-shadow: 0px 20px 40px rgba(81, 109, 83, 1), 0px -20px 40px rgba(99, 153, 98, 1), 20px 0px 40px rgba(190, 226, 191, 1), -20px 0px 40px rgba(190, 226, 191, 1);
    }
  
    100% {
        width: 80%;
        height: 50px;
        opacity: 1;
        box-shadow: 0px 20px 20px rgba(99, 153, 98, 0.5), 0px -20px 20px rgba(81, 109, 83, 0.5), 20px 0px 20px rgba(190, 226, 191, 0.5), -20px 0px 20px rgba(190, 226, 191, 0.5);
    }
  }
  

.FirstTimeStep1 .up {
  position: relative;
  bottom: 100px;
  animation: upper 0.5s ease-in-out; /* Added fade animation */
}

@keyframes upper {
  0% {
      bottom: -30px;
    }
    100% {
    bottom: 100px;
  }
}



}
